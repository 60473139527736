//import logo from './logo.svg';
import './App.css';

function App() {
  return (
   <>
   hello world prahlad
   </>
  );
}

export default App;
